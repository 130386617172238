enum ApiErrorSection {
  DEFAULT = 0,
  TOKEN = 50,
  USER = 100,
  SOCKET_DEFAULT = 10000,
  SOCKET_MESSAGE = 10100
}
export enum ApiErrorCode {
  // DEFAULT
  NO_ERRORS = ApiErrorSection.DEFAULT,
  NOT_FOUND,
  FILE_NOT_FOUND,
  INTERNAL_ERROR,
  DEFAULT_ERROR,
  UNKNOWN_ERROR,
  PERMISSION_DENIED, 
  RESOURCE_DENIED, 
  UPDATE_RESOURCE_ERROR,
  DELETE_RESOURCE_ERROR,
  VALIDATION_ERROR,
  HTTP_ERROR,

  // TOKEN
  TOKEN_UNKNOWN_ERROR = ApiErrorSection.TOKEN,
  TOKEN_NOT_FOUND,
  TOKEN_EXPIRED,
  TOKEN_IS_INVALID,
  REFRESH_TOKEN_IS_EXPIRED,
  REFRESH_TOKEN_IS_INVALID,

  // USER
  USER_UNKNOWN_ERROR = ApiErrorSection.USER,
  USER_NOT_FOUND,

  // SOCKET
  SOCKET_UNKNOWN_ERROR = ApiErrorSection.SOCKET_DEFAULT,
  SOCKET_AUTHENTICATION,
  SOCKET_UNAUTHORIZED,
  SOCKET_ON_CONNECT,
  SOCKET_ON_DISCONNECT,
  SOCKET_DEPRECATED,
  SOCKET_UNKNWON_CHANNEL,

  // SOCKET - MESSAGE 
  SOCKET_MESSAGE_UNKNOWN_ERROR = ApiErrorSection.SOCKET_MESSAGE,
  SOCKET_MESSAGE_MIDDLEARE,
  SOCKET_MESSAGE_FORMAT,
  SOCKET_MESSAGE_DISPATCH,
  SOCKET_MESSAGE_UNAUTHORIZED,
  SOCKET_MESSAGE_NOT_FOUND_FROM_CORRELATION_ID,
  SOCKET_MESSAGE_RESPONSE_TIMEOUT,
  SOCKET_MESSAGE_RESPONSE_ERROR,
}