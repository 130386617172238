import React from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../../components/page/PageContainer";
import { VHOST } from "../../constants/ovenmedia";
import { OvenMediaAppContext, OvenMediaStreamContext } from "../../interfaces/context";
import { OvenMediaAppStats } from "../../interfaces/stats";
import { useOvenMediaContext, useOvenMediaStats } from "../../store/context/hooks";
import TableApplication, { TableApplicationData } from "./container/table-application";

import styles from "./application.module.css";
import ApplicationNetworkContainer from "./container/network";

const ApplicationPage = () => {
  const { app } = useParams();
  const stats = useOvenMediaStats();
  const context = useOvenMediaContext();
  const data: TableApplicationData[] = [];
  let appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;

  let appStats: OvenMediaAppStats | undefined;

  if (app) {
    const currAppContext = appContext;
    appStats = app ? stats?.vhosts[VHOST]?.apps?.[app] : undefined;
    if (currAppContext) {
      const streams = Object.keys(currAppContext?.streams);
      streams.forEach((stream) => {
        const streamContext: OvenMediaStreamContext = currAppContext.streams?.[stream];
        if (streamContext) {
          data.push({
            app: currAppContext,
            context: streamContext,
            stats: appStats?.streams[stream],
          });
        }
      });
    }
  } else if (context?.vhosts[VHOST]?.apps) {
    const apps = Object.keys(context?.vhosts[VHOST]?.apps);
    apps.forEach((app) => {
      const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
      const currAppStats = app ? stats?.vhosts[VHOST]?.apps?.[app] : undefined;
      if (appContext) {
        const streams = Object.keys(appContext?.streams);
        streams.forEach((stream) => {
          const streamContext: OvenMediaStreamContext = appContext.streams?.[stream];
          if (streamContext) {
            data.push({
              app: appContext,
              context: streamContext,
              stats: currAppStats?.streams[stream],
            });
          }
        });
      }
    });
  }

  return (
    <PageContainer title={"Application" /* TRANSLATION */} subtitle={app} loading={!context}>
      <div style={{ width: "100%" }}>
        {data?.length ? (
          <>
            <div className={styles.sectionTitle}>{"ACTIVE LIVE STREAMS" /* TRANSLATION */}</div>
            <TableApplication showApp={app === undefined} data={data} />
          </>
        ) : (
          <div className={styles.noStream}>{"Aucun stream en cours ..." /* TRANSLATION */}</div>
        )}
        <br />
        {app ? (
          <>
            <ApplicationNetworkContainer stats={appStats?.network} />
            <br />
          </>
        ) : null}
        {appContext ? (
          <>
            <div className={styles.sectionTitle}>{"Providers (incoming)" /* TRANSLATION */}</div>
            <div className={styles.listContainer}>
              {appContext?.providers?.map((p) => {
                return (
                  <div key={`provider_${p}`} className={styles.listItem}>
                    {p}
                  </div>
                );
              })}
            </div>

            <div className={styles.sectionTitle}>{"Publishers (outgoing)" /* TRANSLATION */}</div>
            <div className={styles.listContainer}>
              {appContext?.publishers?.map((p) => {
                return (
                  <div key={`publisher_${p}`} className={styles.listItem}>
                    {p}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </PageContainer>
  );
};

export default ApplicationPage;
