import { AppVersion, Touchable } from '@kalyzee/kast-app-web-components';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterContext } from '../../app/RouterContext';

import { ReactComponent as IconBack } from '../../assets/icons/back.svg';
import { useAppDispatch } from '../../hooks/app';
import { requestLogout } from '../../store/request/actions';

import styles from './logout.module.css';

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const route = useContext(RouterContext);

  const onLogout = () => {
    dispatch(requestLogout())
  };

  return (
    <div className={styles.container}>
      <Touchable
        onPress={() => {
          let nextRoute = route?.from;
          if (!nextRoute || nextRoute === route.current) {
            nextRoute = '/';
          }
          navigate(nextRoute);
        }}
        className={styles.backButton}
      >
        <IconBack width="100%" height="100%" />
      </Touchable>
      <div className={styles.logoutContainer}>
        <div className={styles.title}>{'Logout' /* TRANSLATION */}</div>
        <Touchable
          onPress={onLogout}
          className={styles.button}
        >
          {'Se déconnecter' /* TRANSLATION */}
        </Touchable>
      </div>
      <AppVersion />
    </div>
  );
};

export default LogoutPage;
