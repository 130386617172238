import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OvenMediaContext } from '../../interfaces/context';
import { OvenMediaStats } from '../../interfaces/stats';

export interface ContextState {
  context?: OvenMediaContext,
  stats?: OvenMediaStats,
}

const initialState: ContextState = {
  context: undefined,
  stats: undefined
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setContext: (state, action: PayloadAction<OvenMediaContext>) => {
      state.context = action.payload;
    },
    setStats: (state, action: PayloadAction<OvenMediaStats>) => {
      state.stats = action.payload;
    },
  },
});

export const {
  setContext,
  setStats,
} = socketSlice.actions;

export default socketSlice.reducer;
