import { PopupButtonType, showPopup, Touchable } from '@kalyzee/kast-app-web-components';
import React, { useImperativeHandle, useRef } from 'react';
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableStyle } from '../../../components/utils/Table';
import { useRender } from '../../../hooks/component';
import { useElementSize } from '../../../hooks/window';
import { OvenMediaAppOutputProfileEncodeAudio } from '../../../interfaces/context';
import { useOvenMediaContext } from '../../../store/context/hooks';
import styles from './table-video-encoder.module.css';




export interface TableAudioEncoderData extends OvenMediaAppOutputProfileEncodeAudio { }

export interface TableAudioEncoderRef {
  render: () => void;
}

export interface TableAudioEncoderProps {
  data: TableAudioEncoderData[];
  onItemChecked?: (item: TableAudioEncoderData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableAudioEncoder = React.forwardRef((
  {
    data,
    onItemChecked,
    className,
    style,
  }: TableAudioEncoderProps,
  forwardRef: React.ForwardedRef<TableAudioEncoderRef | undefined>,
) => {
  const context = useOvenMediaContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const render = useRender();
  const size = useElementSize(containerRef);


  useImperativeHandle(forwardRef, () => ({
    render,
  }));

  const generateConfiguration = () => {
    const columnConfiguration: TableConfColumn<TableAudioEncoderData>[] = [
      /* {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      }, */
      {
        type: TableColumnType.CLASSIC,
        key: 'name',
        width: '15%',
        minWidth: '10rem',
        title: 'Name' /* TRANSLATION */,
        header: { className: styles.tableHeaderCellName },
        item: { className: styles.tableCellName },
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'bypass',
        title: 'Bypass' /* TRANSLATION */,
        width: '4rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'bypassifmatch',
        title: 'Bypass if match' /* TRANSLATION */,
        width: '8rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'codec',
        title: 'Codec' /* TRANSLATION */,
        width: '5rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'bitrate',
        title: 'Bitrate (Mbps)' /* TRANSLATION */,
        width: '8rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'channel',
        title: 'Channel' /* TRANSLATION */,
        width: '5rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'samplerate',
        title: 'Samplerate' /* TRANSLATION */,
        width: '7rem',
      },
    ];

    const tableConfiguration: TableConf<TableAudioEncoderData> = {
      columns: columnConfiguration,
      header: {
        className: styles.tableHeader,
        cell: {
          className: styles.tableHeaderCell,
        },
      },
      row: {
        className: styles.tableRow,
        cell: {
          className: styles.tableRowCell,
        },
      },
      content: { className: styles.tableContent },
      valueToShowIfUndefined: { value: '-', className: styles.tableUndefinedValue },
    };

    return tableConfiguration;
  };

  // Called when a value is changed. Checkboxes here
  const valueChanged = (value: any, columnKey: string, item: TableAudioEncoderData) => {
    if (columnKey === 'checked') onItemChecked?.(item);
  };

  const customRenderCell = (
    element: JSX.Element | null,
    elementRef: TableContentRef,
    columnKey: string,
    item: TableAudioEncoderData,
  ) => {
    if (columnKey === 'bypass') {
      if (item.bypass) {
        return (
          <div className={styles.bypassYes}>
            ✔
          </div>
        );
      }
      return (
        <div className={styles.bypassFalse}>
          ✗
        </div>
      );
    }
    if (columnKey === 'bypassifmatch') {
      if (item.bypassIfMatch) {
        return (
          <Touchable className={styles.bypassifmatchYes} onPress={() => {
            showPopup({
              content: (
                <>
                <div style={{ marginBottom: 10, minWidth: '200px', fontWeight: 'bold' }}>{'Bypass if match:' /* TRANSLATION */}</div>
                  <div className={styles.bypassifmatchDetailsRow}>
                    <div>{`• codec: ` /* TRANSLATION */}</div>
                    <div>{`${item.bypassIfMatch?.codec ?? '-'}`}</div>
                  </div>
                  <div className={styles.bypassifmatchDetailsRow}>
                    <div>{`• samplerate: ` /* TRANSLATION */}</div>
                    <div>{`${item.bypassIfMatch?.samplerate ?? '-'}`}</div>
                  </div>
                  <div className={styles.bypassifmatchDetailsRow}>
                    <div>{`• channel: ` /* TRANSLATION */}</div>
                    <div>{`${item.bypassIfMatch?.channel ?? '-'}`}</div>
                  </div>
                </>
              ),
              buttons: [{ type: PopupButtonType.OK, element: 'OK' }]
            })
          }}>✔</Touchable>
        );
      }
      return (
        <div className={styles.bypassifmatchFalse}>
          ✗
        </div>
      )
    }
    if (columnKey === 'bitrate' && item.bitrate) {
      const bitrate = Number(item.bitrate ?? '0');
      const bps = Number.isNaN(bitrate) ? 0 : bitrate;
      const kbps = (bps / 1000).toFixed(2);
      const mbits = (bps / 1000000).toFixed(2);
      return (
        <Touchable className={styles.bitrate} onPress={() => {
          showPopup({
            content: (
              <div style={{ minWidth: 300 }}>
                <div style={{ marginBottom: 10, fontWeight: 'bold' }}>{'Bitrate:' /* TRANSLATION */}</div>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'space-between' }}>
                    <div>bits/s: </div>
                    <div style={{}}>{`${bps} bits/s`}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'space-between' }}>
                    <div>kbits/s: </div>
                    <div style={{}}>{`${kbps} kbits/s`}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'space-between' }}>
                    <div>Mbits/s: </div>
                    <div style={{}}>{`${mbits} Mbits/s`}</div>
                  </div>
                </div>
              </div>
            ),
            buttons: [{ type: PopupButtonType.OK, element: 'OK' }]
          }, undefined, { displayHover: true })
        }}>{`${kbps} kbits/s`}</Touchable>
      );
    }
    return element;
  };

  const addCustomStyleOnCell = (
    columnKey: string,
    item: TableAudioEncoderData,
  ) => {
    const result: TableStyle = {};
    return result;
  };

  const addCustomStyleOnRow = (
    item: TableAudioEncoderData,
    currData: TableAudioEncoderData[],
    index: number,
  ) => {
    const result: TableStyle = {};
    return result;
  };

  const onRenderTableStarts = () => {
  };

  const onRenderTableEnded = () => {
  };

  const renderTable = () => (
    <Table
      className={styles.table}
      data={data}
      keyExtractor={(_, item) => `key-${item.name}`}
      configuration={generateConfiguration()}
      onRenderCellRow={customRenderCell}
      onStyleCellRow={addCustomStyleOnCell}
      onStyleRow={addCustomStyleOnRow}
      onChangeValue={valueChanged}
      onRenderStarts={() => onRenderTableStarts}
      onRenderEnded={onRenderTableEnded}
    />
  );

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {renderTable()}
    </div>
  );
});

TableAudioEncoder.defaultProps = {
  className: undefined,
  style: undefined,
};

export default TableAudioEncoder;
