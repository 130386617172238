import { RootState } from '../store';

export const selectToken = (state: RootState) => state.session.token;
export const selectRefreshToken = (state: RootState) => state.session.refreshToken;
export const selectIsRefreshingToken = (state: RootState) => state.session.isRefreshingToken;
export const selectAuthStatus = (state: RootState) => state.session.authStatus;

export default {
  selectToken,
  selectRefreshToken,
  selectIsRefreshingToken,
  selectAuthStatus,
};
