import React from 'react';
import DashboardNetworkStats from '../../../components/dashboard/network';
import { OvenMediaNetworkStats } from '../../../interfaces/stats';
import { useOvenMediaStats } from '../../../store/context/hooks';
import styles from './network.module.css';



export interface ApplicationNetworkContainerProps {
  stats?: OvenMediaNetworkStats;
  className?: string;
  style?: React.CSSProperties;
}


const ApplicationNetworkContainer = ({
  stats,
  className,
  style,
}: ApplicationNetworkContainerProps) => {
  const classes = [styles.container];
  if (className) classes.push(className);
  if (!stats) return null;
  return (
    <div className={classes.join(' ')} style={style}>
      <div className={styles.sectionTitle}>NETWORK:</div>
      <div className={styles.cardsContainer}>
        <DashboardNetworkStats
          mode='incoming'
          stats={stats}
          className={styles.detailsContainer}
        />
        <DashboardNetworkStats
          mode='outgoing'
          stats={stats}
          className={styles.detailsContainer}
        />
      </div>
    </div>
  );
};

ApplicationNetworkContainer.defaultProps = {};


export default ApplicationNetworkContainer;
