import React from 'react';
import RCPagination from 'rc-pagination';
import { cssPropertiesToStyledComponent } from '../../helpers/utils';
import Fonts from '../../constants/fonts';
import Chevron from '../../assets/icons/chevron.svg';

const DEFAULT_TEXT_COLOR = 'black';
const DEFAULT_TEXT_COLOR_SELECTED = 'gold';

export type PaginiationOnSelected = (selected : number) => void;

export interface PaginationProps {
  textColor?: string,
  textColorSelected?: string,
  count: number;
  selected?: number;
  onSelected?: PaginiationOnSelected;
  style?: React.CSSProperties;
}

const containerStyle : React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '3px',
  fontFamily: Fonts.getHalisR(),
  fontSize: '1em',
  position: 'relative',
};

const itemStyle : React.CSSProperties = {
  cursor: 'pointer',
};

const itemActiveStyle : React.CSSProperties = {
  fontSize: '1.2em',
};

const buttonStyle : React.CSSProperties = {
  backgroundImage: `url(${Chevron})`,
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  width: '15px',
  height: '15px',
  margin: '0px 10px',
  cursor: 'pointer',
  border: 'unset',
};

const buttonDisabled : React.CSSProperties = {
  opacity: '0.2',
};

const buttonLeftStyle : React.CSSProperties = {
  transform: 'rotate(-90deg)',
  cursor: 'pointer',
  /*position: 'absolute',*/
  left: '10px',
};

const buttonRightStyle : React.CSSProperties = {
  transform: 'rotate(90deg)',
  cursor: 'pointer',
  /*position: 'absolute',*/
  right: '10px',
};

const jumptStyle : React.CSSProperties = {
  cursor: 'pointer',
  border: 'unset',
  backgroundColor: 'transparent',
  fontFamily: Fonts.getHalisR(),
  fontSize: '1em',
};

const JUMP_BUTTON_CONTENT = '...';

const getCss = (textColor : string, textColorSelected : string) : string => `
.rc-pagination {${cssPropertiesToStyledComponent(containerStyle)}}
.rc-pagination-item {${cssPropertiesToStyledComponent({ ...itemStyle, color: textColor })}}
.rc-pagination-item-active {${cssPropertiesToStyledComponent({ ...itemActiveStyle, color: textColorSelected })}}
.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link {${cssPropertiesToStyledComponent({ ...buttonStyle, color: textColor })}}
.rc-pagination-prev {${cssPropertiesToStyledComponent(buttonLeftStyle)}}
.rc-pagination-next {${cssPropertiesToStyledComponent(buttonRightStyle)}}
.rc-pagination-jump-prev .rc-pagination-item-link, .rc-pagination-jump-next .rc-pagination-item-link  {${cssPropertiesToStyledComponent({ ...jumptStyle, color: textColor })}}
.rc-pagination-disabled {${cssPropertiesToStyledComponent(buttonDisabled)}}
.rc-pagination-jump-prev .rc-pagination-item-link:after, .rc-pagination-jump-next .rc-pagination-item-link:after {content:'${JUMP_BUTTON_CONTENT}'}
li {
  list-style-type: none;
}
ul {
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
`;

const Pagination = ({
  textColor,
  textColorSelected,
  count,
  selected,
  onSelected,
  style,
}: PaginationProps) => {
  const css = getCss(
    textColor || DEFAULT_TEXT_COLOR,
    textColorSelected || DEFAULT_TEXT_COLOR_SELECTED,
  );
  return (
    <div style={style}>
      <style>{ css }</style>
      <RCPagination
        current={selected ? selected + 1 : 1}
        total={count}
        pageSize={1}
        locale={{}}
        onChange={(page : number, pageSize : number) => onSelected?.(page - 1)}
      />
    </div>
  );
};

Pagination.defaultProps = {
  textColor: DEFAULT_TEXT_COLOR,
  textColorSelected: DEFAULT_TEXT_COLOR_SELECTED,
  selected: 0,
  onSelected: undefined,
  style: undefined,
};

export default Pagination;
