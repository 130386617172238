
/*
 * Use this template:
 *
 * export default {
 *  getDark: (opacity : number = 1) : string => `rgba(r, g, b, ${opacity})`, // #HEXCODE
 *  getDarkBlue: (opacity : number = 1) : string => `rgba(r, g, b, ${opacity})`, // #HEXCODE
 * }
 *
 * It will force a common "code" (rgba, over HEX or css) and allow nuances via opacity
 *
 * To get color name use this site: https://chir.ag/projects/name-that-color/#FFFFFF
 */

export const Colors = {
  getMainWhite: (opacity : number = 1) : string => `rgba(255, 255, 255, ${opacity})`, // #FFFFFF
  getMainGreen: (opacity : number = 1) : string => `rgba(23, 191, 135, ${opacity})`, // #17BF87
  getMainBlack: (opacity : number = 1) : string => `rgba(0, 0, 0, ${opacity})`, // #000000
  getMainRed: (opacity : number = 1) : string => `rgba(255, 0, 0, ${opacity})`, // #FF0000
  getMainWheat: (opacity : number = 1) : string => `rgba(245, 222, 179, ${opacity})`, // #F5DEB3


  getMainMerino: (opacity : number = 1) : string => `rgba(247, 242, 232, ${opacity})`, // #F7F2E8
  getMainGrainBrown: (opacity : number = 1) : string => `rgba(221, 206, 176, ${opacity})`, // #DDCEB0
  getMainIndianKhaki: (opacity : number = 1) : string => `rgba(196, 178, 143, ${opacity})`, // #C4B28F
  getMainTabaccoBrown: (opacity : number = 1) : string => `rgba(100, 90, 67, ${opacity})`, // #645A43
  getMainWestCoast: (opacity : number = 1) : string => `rgba(107, 81, 26, ${opacity})`, // #6B511A

  // Red
  getRed: (opacity : number = 1) : string => `rgba(237, 12, 12, ${opacity})`, // #ED0C0C
  getDeleteRed: (opacity : number = 1) : string => `rgba(255, 14, 14, ${opacity})`, // #FF0E0E
  getTorchRed: (opacity : number = 1) : string => `rgba(255, 14, 67, ${opacity})`, // #FF0E43

  // Yellow
  getBroom: (opacity : number = 1) : string => `rgba(255, 235, 32, ${opacity})`, // #FFEB20

  // Orange
  getTreePoppy: (opacity : number = 1) : string => `rgba(255, 152, 32, ${opacity})`, // #FF9820

  // Blue
  getClayEbony: (opacity : number = 1) : string => `rgba(37, 49, 68, ${opacity})`, // #253144
  getClayEbonyMedium: (opacity : number = 1) : string => `rgba(34, 45, 63, ${opacity})`, // #222D3F
  getClayEbonyDark: (opacity : number = 1) : string => `rgba(32, 42, 58, ${opacity})`, // #202A3A

  getBlueBayoux: (opacity : number = 1) : string => `rgba(80, 94, 117, ${opacity})`, // #505e75
  getPickledBluewood: (opacity : number = 1) : string => `rgba(42, 55, 77, ${opacity})`, // #2A374D
  getPickledBluewoodDark: (opacity : number = 1) : string => `rgba(38, 51, 72, ${opacity})`, // #263348
  getBluePrussian: (opacity : number = 1) : string => `rgba(0, 36, 90, ${opacity})`, // #00245A
  getBlueOxford: (opacity : number = 1) : string => `rgba(56, 67, 84, ${opacity})`, // #384354
  getBlueDodger: (opacity : number = 1) : string => `rgba(0, 186, 255, ${opacity})`, // #00BAFF
  getBlueCornflower: (opacity : number = 1) : string => `rgba(112, 87, 255, ${opacity})`, // #7057FF

  // Grey
  getGrayLevel: (level : number, opacity : number = 1) : string => {
    const value = Math.max(0, Math.min(Math.floor(level * 255)));
    return `rgba(${value}, ${value}, ${value}, ${opacity})`;
  }, // level : 0 -> 1
  getGraySantas: (opacity : number = 1) : string => `rgba(156, 153, 177, ${opacity})`, // #9C99B1
  getGrayAthens: (opacity : number = 1) : string => `rgba(248, 248, 249, ${opacity})`, // #F8F8F9
  getGrayDusty: (opacity : number = 1) : string => `rgba(151, 151, 151, ${opacity})`, // #979797
  getCodGray: (opacity : number = 1) : string => `rgba(17, 17, 17, ${opacity})`, // #111111
  getSilverChalice: (opacity : number = 1) : string => `rgba(171, 171, 171, ${opacity})`, // #ABABAB

  // Purple
  getPurpleHeart: (opacity : number = 1) : string => `rgba(82, 59, 209, ${opacity})`, // #523BD1

  // Green
  getMountainMeadow: (opacity : number = 1) : string => `rgba(23, 191, 136, ${opacity})`, // #17bf88
  getSalem: (opacity : number = 1) : string => `rgba(12, 131, 92, ${opacity})`, // #0C835C

  // Rose
  getRoseOld: (opacity : number = 1) : string => `rgba(198, 154, 126, ${opacity})`, // #C69A7E
  getClamShell: (opacity : number = 1) : string => `rgba(219, 197, 183, ${opacity})`, // #DBC5B7
}

export default Colors;