import { Touchable } from "@kalyzee/kast-app-web-components";
import React, { useRef, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { ReactComponent as IconCpu } from "../../../assets/icons/cpu.svg";
import { ReactComponent as IconDisk } from "../../../assets/icons/disk.svg";
import { ReactComponent as IconMemory } from "../../../assets/icons/memory.svg";
import Colors from "../../../constants/colors";
import { useOvenMediaStats } from "../../../store/context/hooks";
import styles from "./system.module.css";

enum CardKey {
  CPU = "cpu",
  MEMORY = "memory",
  HOST_DISK = "host_disk",
  VIDEO_DISK = "video_disk",
}

type CardState = {
  [key in CardKey]: {
    enableAdditionalStats: boolean;
    opened: boolean;
  };
};

type CardMetaData = {
  [key in CardKey]: {
    icon: React.ReactNode;
    title: string;
  };
};

type CardData = {
  [key in CardKey]: {
    value: number;
    average: number;
    min?: number;
    max?: number;
    startedAt?: Date;
    last?: Date;
  };
};

export interface DashboardSystemContainerProps {
  className?: string;
  style?: React.CSSProperties;
}

const DashboardSystemContainer = ({ className, style }: DashboardSystemContainerProps) => {
  const now = new Date();
  const stats = useOvenMediaStats();

  const cardDataRef = useRef<CardData>({
    [CardKey.CPU]: { value: 0, average: 0, min: undefined, max: undefined, startedAt: now, last: now },
    [CardKey.MEMORY]: { value: 0, average: 0, min: undefined, max: undefined, startedAt: now, last: now },
    [CardKey.HOST_DISK]: { value: 0, average: 0, min: undefined, max: undefined, startedAt: now, last: now },
    [CardKey.VIDEO_DISK]: { value: 0, average: 0, min: undefined, max: undefined, startedAt: now, last: now },
  });

  const [cardState, setCardState] = useState<CardState>({
    [CardKey.CPU]: { enableAdditionalStats: true, opened: true },
    [CardKey.MEMORY]: { enableAdditionalStats: true, opened: true },
    [CardKey.HOST_DISK]: { enableAdditionalStats: true, opened: true },
    [CardKey.VIDEO_DISK]: { enableAdditionalStats: true, opened: true },
  });

  if (!stats?.system) return null;

  const cardMetadata: CardMetaData = {
    [CardKey.CPU]: { icon: <IconCpu width={25} height={25} />, title: "CPU" /* TRANSLATION */ },
    [CardKey.MEMORY]: { icon: <IconMemory width={25} height={25} />, title: "MEMORY" /* TRANSLATION */ },
    [CardKey.HOST_DISK]: { icon: <IconDisk width={25} height={25} />, title: "HOST DISK" /* TRANSLATION */ },
    [CardKey.VIDEO_DISK]: { icon: <IconDisk width={25} height={25} />, title: "VIDEO DISK" /* TRANSLATION */ },
  };

  const updateCardData = (key: CardKey, value: number) => {
    cardDataRef.current[key].value = value;
    cardDataRef.current[key].min = cardDataRef.current[key].min ? Math.min(cardDataRef.current[key].min!, value) : value;
    cardDataRef.current[key].max = cardDataRef.current[key].max ? Math.max(cardDataRef.current[key].max!, value) : value;
    if (cardDataRef.current[key].last && cardDataRef.current[key].startedAt) {
      if (now.getTime() !== cardDataRef.current[key].startedAt!.getTime()) {
        cardDataRef.current[key].average =
          (cardDataRef.current[key].average * (cardDataRef.current[key].last!.getTime() - cardDataRef.current[key].startedAt!.getTime()) +
            value * (now.getTime() - cardDataRef.current[key].last!.getTime())) /
          (now.getTime() - cardDataRef.current[key].startedAt!.getTime());
      } else {
        cardDataRef.current[key].average = value;
      }
    } else {
      cardDataRef.current[key].average = value;
      cardDataRef.current[key].startedAt = now;
    }
    cardDataRef.current[key].last = now;
  };

  const cpuUsage = (stats?.system.cpuUsage ?? 0) * 100;
  const memoryUsage = (stats?.system.memoryUsage ?? 0) * 100;
  const hostDiskUsage = (stats?.system.hostDiskUsage ?? 0) * 100;
  const videoDiskUsage = (stats?.system.videoDiskUsage ?? 0) * 100;

  updateCardData(CardKey.CPU, cpuUsage);
  updateCardData(CardKey.MEMORY, memoryUsage);
  updateCardData(CardKey.HOST_DISK, hostDiskUsage);
  updateCardData(CardKey.VIDEO_DISK, videoDiskUsage);

  const formatPercentage = (value: number) => {
    return `${value?.toFixed(0)}%`;
  };
  const classes = [styles.container];
  if (className) classes.push(className);

  const getUsageColor = (value: number) => {
    if (value < 60) return Colors.getMainGreen();
    if (value < 80) return Colors.getTreePoppy();
    return Colors.getTorchRed();
  };

  const renderCard = (key: CardKey) => {
    const data = cardDataRef.current[key];
    return (
      <div className={styles.detailsContainer}>
        <div className={styles.headerDetailsContainer}>
          {cardMetadata[key].icon}
          <div className={styles.detailsTitle}>{cardMetadata[key].title}</div>
        </div>
        <br />
        <div style={{ width: 100, height: 100 }}>
          <CircularProgressbar
            styles={buildStyles({
              pathColor: getUsageColor(data.value),
              textColor: getUsageColor(data.value),
            })}
            value={data.value}
            text={formatPercentage(data.value)}
          />
        </div>
        {cardState[key].opened ? (
          <div style={{ width: "100%", marginBottom: 5 }}>
            <br />
            <div className={styles.detailsRow}>
              <span className={styles.detailsTitle}>{"Average:" /* TRANSLATION */}</span>
              <span className={styles.detailsValue}>{formatPercentage(data.average)}</span>
            </div>
            <div className={styles.detailsRow}>
              <span className={styles.detailsTitle}>{"Min:" /* TRANSLATION */}</span>
              <span className={styles.detailsValue}>{formatPercentage(data.min ?? 0)}</span>
            </div>
            <div className={styles.detailsRow}>
              <span className={styles.detailsTitle}>{"Max:" /* TRANSLATION */}</span>
              <span className={styles.detailsValue}>{formatPercentage(data.max ?? 0)}</span>
            </div>
            <br />
            {data.startedAt ? (
              <div className={styles.startAt}>{`Started at: ${data.startedAt.toLocaleDateString()} ${data.startedAt.toLocaleTimeString()}`}</div>
            ) : null}
            <Touchable
              className={styles.resetButton}
              onPress={() => {
                cardDataRef.current[key].average = 0;
                cardDataRef.current[key].min = undefined;
                cardDataRef.current[key].max = undefined;
                cardDataRef.current[key].startedAt = undefined;
                cardDataRef.current[key].last = undefined;
                // Refresh now
                setCardState({ ...cardState });
              }}
            >
              {"Reset" /* TRANSLATION */}
            </Touchable>
          </div>
        ) : null}
        {cardState[key].enableAdditionalStats ? (
          <Touchable
            className={styles.openCloseContainer}
            onPress={() => {
              const newState = { ...cardState };
              newState[key].opened = !newState[key].opened;
              setCardState(newState);
            }}
          >
            {cardState[key].opened ? "▲" : "▼"}
          </Touchable>
        ) : null}
      </div>
    );
  };

  return (
    <div className={classes.join(" ")} style={style}>
      <div className={styles.sectionTitle}>{"SYSTEM:" /* TRANSLATION */}</div>
      <div className={styles.rowContainer}>
        {renderCard(CardKey.CPU)}
        {renderCard(CardKey.MEMORY)}
        {renderCard(CardKey.HOST_DISK)}
        {renderCard(CardKey.VIDEO_DISK)}
      </div>
    </div>
  );
};

DashboardSystemContainer.defaultProps = {};

export default DashboardSystemContainer;
