import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getToken as getLocalStorageToken, removeToken as removeLocalStorageToken, TokenType } from '../../helpers/storage';
import { AuthStatus } from '../../interfaces/session';

export interface SessionState {
  token: string | null,
  refreshToken: string | null,
  isRefreshingToken: boolean,
  authStatus: AuthStatus,
}

const initialState: SessionState = {
  token: getLocalStorageToken(),
  refreshToken: getLocalStorageToken(TokenType.Refresh),
  isRefreshingToken: false,
  authStatus: getLocalStorageToken() ? AuthStatus.Loading : AuthStatus.Out,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setIsRefreshingToken: (state, action: PayloadAction<boolean>) => {
      state.isRefreshingToken = action.payload;
    },
    setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.authStatus = action.payload;
    },
    reset: (state) => {
      state.token = getLocalStorageToken();
      state.refreshToken = getLocalStorageToken(TokenType.Refresh);
      state.isRefreshingToken = false;
      state.authStatus = getLocalStorageToken() ? AuthStatus.Loading : AuthStatus.Out;
    },
    logout: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.isRefreshingToken = false;
      state.authStatus = AuthStatus.Out;
      removeLocalStorageToken(TokenType.Normal);
      removeLocalStorageToken(TokenType.Refresh)
    }
  },
});

export const {
  setToken,
  setRefreshToken,
  setIsRefreshingToken,
  setAuthStatus,
  reset,
  logout,
} = sessionSlice.actions;

export default sessionSlice.reducer;
