import { createAction } from '@reduxjs/toolkit';
import { RequestLoginLocal, RequestRefreshToken } from '../../interfaces/request';

export const requestLoginLocal = createAction<RequestLoginLocal>('request_login_local');
export const requestLogout = createAction('request_logout');
export const requestRefreshToken = createAction<RequestRefreshToken>('request_refresh_token');
export const refreshTokenFailed = createAction('refresh_token_failed')

export default {
  requestLoginLocal,
  requestLogout,
};
