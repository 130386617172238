import { AppVersion } from '@kalyzee/kast-app-web-components';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as KalyzeeSvg } from '../../assets/logo/logo-animated.svg';
import OvenMediaPng from '../../assets/logo/ovenmedia.png';
import { useAppDispatch } from '../../hooks/app';
import { requestLoginLocal } from '../../store/request/actions';
import styles from './login.module.css';


const LoginPage = () => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const renderRequiredField = () => (
    <span className={styles.fieldError}>
      {'Ce champ est requis' /* TRANSLATION */}
    </span>
  );

  const onSubmit = (data: any) => {
    dispatch(requestLoginLocal(data));
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img className={styles.logoOvenmedia} src={OvenMediaPng} alt="" />
        <div className={styles.logoKalyzeeContainer}>
          <div className={styles.by}>By</div>
          <KalyzeeSvg className={styles.logoKalyzee} />
        </div>
      </div>
      <form className={styles.loginContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.title}>{'Login' /* TRANSLATION */}</div>
        <input className={styles.input} placeholder={'Email' /* TRANSLATION */} {...register('email', { required: true })} />
        {errors.email ? renderRequiredField() : null}
        <input className={styles.input} placeholder={'Password' /* TRANSLATION */} type="password" {...register('password', { required: true })} />
        {errors.password ? renderRequiredField() : null}
        <input className={styles.button} type="submit" value={'Se connecter' /* TRANSLATION */} />
      </form>
      <AppVersion />
    </div>
  );
};

export default LoginPage;
