
export interface ActionState {
  type: string,
  payload: any,
  meta: any,
  error: boolean
}

const initialState = {
  type: null,
  payload: null,
  meta: null,
  error: false
};

export const actionReducer = (state = initialState, action: any) => {
  return {
    ...state,
    ...action
  };
};