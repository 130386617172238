import { useEffect, useRef } from "react";
import { useStore } from "react-redux";
import { ActionState } from "../store/action";
import { StoreState } from "../store/interface";

/**
 * Subscribes to redux store events
 *
 * @param effect
 * @param type
 * @param deps
 */
 export function useReduxAction(effect : (action: ActionState) => void, type: string, deps = []) {
  const store = useStore<StoreState>();

  const handleChange = () => {
    const state = store.getState();
    const action = state.action;
    if (action.type === type) {
      effect(action);
    }
  };

  useEffect(() => {
    const unsubscribe = store.subscribe(handleChange);
    return () => unsubscribe();
  }, deps);
}