import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  Middleware,
  MiddlewareAPI,
  PayloadAction
} from '@reduxjs/toolkit';
import { getBaseUrl, getUserSocketUrl } from '../../helpers/request';
import { OvenMediaSocket, OvenMediaSocketCommand, OvenMediaSocketCommandAction, OvenMediaSocketConnectionError, OvenMediaSocketResultEmittedCommand } from '../../helpers/socket';
import { getToken } from '../../helpers/storage';
import { toastError } from '../../helpers/toast';
import { addToPathNameUrl } from '../../helpers/utils';
import { SocketStatus } from '../../interfaces/socket';
import { contextOnLogs } from '../context/actions';
import { setContext, setStats } from '../context/slices';
import { logout } from '../session/slices';
import socketActions, { SocketAddRedirectionParams, SocketDeleteRecordParams, SocketDeleteRedirectionParams, SocketGetContextParams, SocketGetSessionParams, SocketStartRecordParams, SocketStopRecordParams, SocketStopStreamParams, SocketUploadRecordParams } from './actions';
import { setSocketStatus } from './slices';

const log = (...args: string[]) => {
  // eslint-disable-next-line no-console
  console.log('[SOCKET] ', ...args);
};

/// Redux Action Handlers ///

interface ActionEntryWithPayload<P = any> {
  actionCreator: ActionCreatorWithPayload<P>,
  handle: (
    socket: OvenMediaSocket,
    store: MiddlewareAPI,
    payload: P,
  ) => (Promise<OvenMediaSocketResultEmittedCommand | undefined> | void),
}

interface ActionEntry {
  actionCreator: ActionCreatorWithoutPayload,
  handle: (socket: OvenMediaSocket, store: MiddlewareAPI) => void,
}

const reduxActionHandlers: (ActionEntry | ActionEntryWithPayload)[] = [
  {
    actionCreator: socketActions.socketConnect,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI) => {
      try {
        if (socket) socket.disconnect();
      } catch {}
      try {
        const userToken = getToken() || '';
        await socket.connect(userToken, addToPathNameUrl(getBaseUrl(), '/socket/token'));
      } catch (err) {
        toastError(`Error during connection websocket : ${err}`);
        store.dispatch(logout());
        store.dispatch(setSocketStatus(SocketStatus.Offline));
      }
      return undefined;
    },
  },
  {
    actionCreator: socketActions.socketDisconnect,
    handle: (socket: OvenMediaSocket, store: MiddlewareAPI) => {
      socket.disconnect();
      return undefined;
    },
  },
  {
    actionCreator: socketActions.socketGetContext,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketGetContextParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_CONTEXT, payload);
    },
  },
  {
    actionCreator: socketActions.socketGetStats,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_STATS);
    },
  },
  {
    actionCreator: socketActions.socketGetLiveSessions,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketGetSessionParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_LIVE_SESSIONS, payload);
    },
  },
  {
    actionCreator: socketActions.socketGetRecordSessions,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketGetSessionParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_RECORD_SESSIONS, payload);
    },
  },
  {
    actionCreator: socketActions.socketSubscribeStats,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_STATS);
    },
  },
  {
    actionCreator: socketActions.socketUnsubscribeStats,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.UNSUBSCRIBE_STATS);
    },
  },
  {
    actionCreator: socketActions.socketSubscribeLogs,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_OVENMEDIA_LOGS);
    },
  },
  {
    actionCreator: socketActions.socketUnsubscribeLogs,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.UNSUBSCRIBE_OVENMEDIA_LOGS);
    },
  },
  {
    actionCreator: socketActions.socketGetLogs,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.GET_OVENMEDIA_LOGS);
    },
  },
  {
    actionCreator: socketActions.socketStopStream,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketStopStreamParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.STOP_STREAM, payload);
    },
  },
  {
    actionCreator: socketActions.socketStartRecord,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketStartRecordParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.START_RECORD, payload);
    },
  },
  {
    actionCreator: socketActions.socketStopRecord,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketStopRecordParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.STOP_RECORD, payload);
    },
  },
  {
    actionCreator: socketActions.socketDeleteRecord,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketDeleteRecordParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.DELETE_RECORD, payload);
    },
  },
  {
    actionCreator: socketActions.socketAddRedirection,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketAddRedirectionParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.ADD_REDIRECTION, payload);
    },
  },
  {
    actionCreator: socketActions.socketUploadRecord,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketUploadRecordParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.UPLOAD_RECORD, payload);
    },
  },
  {
    actionCreator: socketActions.socketDeleteRedirection,
    handle: async (socket: OvenMediaSocket, store: MiddlewareAPI, payload: SocketDeleteRedirectionParams) => {
      return await socket.emitCommand(OvenMediaSocketCommandAction.DELETE_REDIRECTION, payload);
    },
  },
  
];

const reduxActionMatcher = (
  socket: OvenMediaSocket,
  store: MiddlewareAPI,
  action: PayloadAction,
) : Promise<OvenMediaSocketResultEmittedCommand | undefined> | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const handler of reduxActionHandlers) {
    if (handler.actionCreator.match(action)) {
      const result = handler.handle(socket, store, action.payload);
      if (result instanceof Promise) return result;
      return undefined;
    }
  }
  return undefined;
};

/// Middleware ///

export const socketMiddleware: Middleware = (store) => {
  const socket = new OvenMediaSocket(getUserSocketUrl());

  socket.onConnect = () => {
    log('Connected');
    store.dispatch(setSocketStatus(SocketStatus.Online));
    socket.emitCommand(OvenMediaSocketCommandAction.GET_CONTEXT, { refresh: true }).then((result) => {
      if (result?.error) {
        toastError(`Code: ${result?.error.code}\nMessage: ${result?.error.message}`);
        return;
      }
      store.dispatch(setContext(result?.response));
    }).catch((error) => toastError(`Error get context`));
    socket.emitCommand(OvenMediaSocketCommandAction.GET_STATS).then((result) => {
      if (result?.error) {
        toastError(`Code: ${result?.error.code}\nMessage: ${result?.error.message}`);
        return;
      }
      store.dispatch(setStats(result?.response));
    }).catch((error) => toastError(`Error get stats`));
    socket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_STATS);
    socket.emitCommand(OvenMediaSocketCommandAction.SUBSCRIBE_CONTEXT);
  };

  socket.onDisconnect = () => {
    log('Disconnected');
    store.dispatch(setSocketStatus(SocketStatus.Loading));
  };

  socket.onConnectionFailed = (error: OvenMediaSocketConnectionError) => {
    log('Connection Failed');
    store.dispatch(setSocketStatus(SocketStatus.Offline));
  };

  socket.onCommand = (command: OvenMediaSocketCommand) => {
    if(command.action === OvenMediaSocketCommandAction.ON_OVENMEDIA_LOGS) {
      store.dispatch(contextOnLogs({
        content: command.content,
        append: true,
      }));
    }

    if(command.action === OvenMediaSocketCommandAction.ON_CONTEXT) {
      store.dispatch(setContext(command.content));
    }

    if(command.action === OvenMediaSocketCommandAction.ON_STATS) {
      store.dispatch(setStats(command.content));
    }
  }

  // eslint-disable-next-line arrow-body-style
  return (next) => async (action) => {
    const result = reduxActionMatcher(socket, store, action);
    if (result) return result;
    return next(action);
  };
};

export default {
  socketMiddleware,
};
