import { enumContainsString } from './utils';

export enum StorageCategory {
  TOKEN = 'token',
  MENU = 'menu',
  POPUP = 'popup',
}

export enum TokenType {
  Normal = 'normal',
  Refresh = 'refreshToken',
}

export enum MenuKeys {
  DISPLAY_MODE = 'display_mode',
}

export enum PopupKeys {
  NOT_SHOW_AGAIN = 'not_show_again',
}

const storage = localStorage;

// ---------------- NATIVE ------------------ //

const generateKey = (category : StorageCategory, key : string) => (
  `${category}_${key}`
);

const setItem = (
  category : StorageCategory,
  key: string,
  value: string,
) => storage.setItem(generateKey(category, key), value);

const getItem = (
  category : StorageCategory,
  key: string,
) => storage.getItem(generateKey(category, key));

const removeItem = (
  category : StorageCategory,
  key: string,
) => storage.removeItem(generateKey(category, key));

// ---------------- TOKEN ------------------ //

export const setToken = (
  token: string,
  tokenType: TokenType = TokenType.Normal,
) => setItem(StorageCategory.TOKEN, tokenType, token);

export const getToken = (
  tokenType: TokenType = TokenType.Normal,
) => getItem(StorageCategory.TOKEN, tokenType);

export const removeToken = (
  tokenType: TokenType = TokenType.Normal,
) => removeItem(StorageCategory.TOKEN, tokenType);

export default {
  // TOKEN
  setToken,
  getToken,
  removeToken,
};
