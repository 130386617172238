import { PayloadAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { OvenMediaSocketResultEmittedCommand } from '../helpers/socket';
import { AppDispatch, RootState } from '../store/store';

// To use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useSocketAppDispatch = () => {
  const dispatch = useAppDispatch();
  return (
    <T extends OvenMediaSocketResultEmittedCommand, P = any>(
      action: PayloadAction<P>,
    ): Promise<T> => (
      dispatch<Promise<T>, PayloadAction<P>>(action) as Promise<T>
    )
  );
};
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
