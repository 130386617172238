import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './MenuSubitem.module.css';

export interface MenuSubitemProps {
  title: string | React.ReactNode;
  navigateTo?: string;
  onNavigateTo?: (path: string) => void;
  iconSrc?: string;
  iconAlt?: string;
  className?: string;
  style?: React.CSSProperties;
}
export interface MenuSubitemRef {
  select: () => void;
  isSelected: () => boolean;
}
const MenuSubitem = React.forwardRef((
  {
    iconSrc,
    iconAlt,
    title,
    navigateTo,
    onNavigateTo,
    className,
    style,
  }: MenuSubitemProps,
  forwardRef: React.ForwardedRef<MenuSubitemRef | undefined>,
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSelected, setIsSelect] = useState<boolean>(false);

  const locationIsMatching = (): boolean => {
    if (!navigateTo) return false;
    return location.pathname === navigateTo;
  };

  useEffect(() => {
    setIsSelect(locationIsMatching());
  }, []);

  useEffect(() => {
    setIsSelect(locationIsMatching());
  }, [location]);

  const onClick = () => {
    if (!navigateTo) return;
    setIsSelect(true);
    navigate(navigateTo);
    onNavigateTo?.(navigateTo);
  };

  useImperativeHandle(forwardRef, () => ({
    select: () => onClick(),
    isSelected: () => isSelected,
  }));

  const classes = [styles.container];
  if (isSelected) classes.push(styles.selected);
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      role="button"
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={(e) => { if (e.code === 'Enter') onClick(); }}
    >
      {
        iconSrc ? (
          <img
            className={styles.icon}
            src={iconSrc}
            alt={iconAlt}
          />
        ) : null
      }
      <div
        className={styles.title}
      >
        {title}
      </div>
    </div>
  );
});

MenuSubitem.defaultProps = {
  iconSrc: undefined,
  iconAlt: undefined,
  navigateTo: undefined,
  onNavigateTo: undefined,
  className: undefined,
  style: undefined,
};

export default MenuSubitem;
