import React from 'react';

import IconSeparator from '../../assets/icons/next-separator.svg';

import styles from './PageTitle.module.css';

export interface PageTitleProps {
  title: string;
  subtitle?: string;
  className?: string;
  style?: React.CSSProperties;
}

const PageTitle = (
  {
    title,
    subtitle,
    className,
    style,
  }: PageTitleProps,
) => {
  const renderSepartor = () => (
    <img
      className={styles.separator}
      src={IconSeparator}
      alt=""
    />
  );

  const renderSubtitle = () => (
    <div className={styles.subtitle}>
      { subtitle }
    </div>
  );

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      <div className={styles.title}>
        { title }
      </div>
      { subtitle ? renderSepartor() : null }
      { subtitle ? renderSubtitle() : null }
    </div>
  );
};

PageTitle.defaultProps = {
  subtitle: undefined,
  className: undefined,
  style: undefined,
};

export default PageTitle;
