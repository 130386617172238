import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  Middleware,
  MiddlewareAPI,
  PayloadAction
} from '@reduxjs/toolkit';
import { ApiError } from '../../helpers/apiError';
import { request } from '../../helpers/request';
import { setToken as setLocalStorageToken, TokenType } from '../../helpers/storage';
import { toastApiError, toastError, toastSuccess } from '../../helpers/toast';
import { RequestLoginLocal } from '../../interfaces/request';
import { AuthStatus } from '../../interfaces/session';
import { logout, setAuthStatus, setToken } from '../session/slices';
import requestActions from './actions';

/// Redux Action Handlers ///

interface ActionEntryWithPayload<P = any> {
  actionCreator: ActionCreatorWithPayload<P>,
  handle: (
    store: MiddlewareAPI,
    payload: P,
  ) => (Promise<any> | void),
}

interface ActionEntry {
  actionCreator: ActionCreatorWithoutPayload,
  handle: (store: MiddlewareAPI) => void,
}

const reduxActionHandlers: (ActionEntry | ActionEntryWithPayload)[] = [
  {
    actionCreator: requestActions.requestLoginLocal,
    handle: async (store: MiddlewareAPI, payload: RequestLoginLocal) => {
      try {
        const response = await request(store, '/user/login', 'post', payload, false);
        if (!response.token) throw new Error('Token is undefined');
        setLocalStorageToken(response.token, TokenType.Normal);
        if (response.refreshToken) {
          setLocalStorageToken(response.token, TokenType.Refresh);
        }
        store.dispatch(setToken(response.token));
        store.dispatch(setAuthStatus(AuthStatus.In));
        toastSuccess('Bienvenue'/* TRANSLATION */);
      } catch(err: any) {
        if (ApiError.isApiError(err)) toastApiError(err);
        else toastError(`Error login : ${err?.message}`);
      }
      return undefined;
    },
  },
  {
    actionCreator: requestActions.requestLogout,
    handle: (store: MiddlewareAPI) => {
      store.dispatch(logout());
      return undefined;
    },
  },
];

const reduxActionMatcher = (
  store: MiddlewareAPI,
  action: PayloadAction,
): Promise<any> | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const handler of reduxActionHandlers) {
    if (handler.actionCreator.match(action)) {
      const result = handler.handle(store, action.payload);
      if (result instanceof Promise) return result;
      return undefined;
    }
  }
  return undefined;
};

/// Middleware ///

export const requestMiddleware: Middleware = (store) => {
  // eslint-disable-next-line arrow-body-style
  return (next) => async (action) => {
    const result = reduxActionMatcher(store, action);
    if (result) return result;
    return next(action);
  };
};

export default {
  requestMiddleware,
};
