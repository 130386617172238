import React from 'react';
import { ReactComponent as IconLiveStream } from '../../../assets/icons/stream.svg';
import { VHOST } from '../../../constants/ovenmedia';
import { useOvenMediaStats } from '../../../store/context/hooks';
import styles from './stream.module.css';



export interface DashboardStreamContainerProps {
  className?: string;
  style?: React.CSSProperties;
}

const DashboardStreamContainer = ({
  className,
  style,
}: DashboardStreamContainerProps) => {
  const stats = useOvenMediaStats();
  const streams: string[] = [];
  if (stats?.vhosts[VHOST]?.apps) {
    const apps = Object.keys(stats?.vhosts[VHOST]?.apps);
    apps.forEach((app) => {
      const curr = stats?.vhosts[VHOST]?.apps[app]?.streams;
      if (curr) {
        streams.push(...Object.keys(curr));
      }
    })
  }
  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div className={classes.join(' ')} style={style}>
      <div className={styles.sectionTitle}>{'STREAMS:' /* TRANSLATION */}</div>
      <div className={styles.detailsContainer}>
        <IconLiveStream width={20} height={20} />
        <div className={styles.detailsTitle}>
          {'Active live streams:' /* TRANSLATION */}
        </div>
        <div className={styles.detailsValue}>
          {streams.length}
        </div>
      </div>
    </div>
  );
};

DashboardStreamContainer.defaultProps = {};

export default DashboardStreamContainer;
