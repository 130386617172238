import React, { useContext, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Menu from '../../components/menu/Menu';
import { MenuDisplayMode } from '../../components/menu/menu.constant';
import { MenuItemName } from '../../components/menu/MenuItems';
import { useAppDispatch, useAppSelector } from '../../hooks/app';
import { useOuterClick } from '../../hooks/click';
import { useElementSize } from '../../hooks/window';
import { AuthStatus } from '../../interfaces/session';
import { SocketStatus } from '../../interfaces/socket';
import { selectAuthStatus } from '../../store/session/selectors';
import { socketConnect } from '../../store/socket/actions';
import { selectSocketStatus } from '../../store/socket/selectors';
import MenuOutline from '../../assets/icons/menu-outline.svg';

import styles from './home.module.css';
import { Touchable, TouchableImage } from '@kalyzee/kast-app-web-components';

const HomePage = () => {
  const dispatch = useAppDispatch();
  const socketStatus = useAppSelector(selectSocketStatus);
  const authStatus = useAppSelector(selectAuthStatus);
  const socketFirstConnectionRef = useRef<boolean>(true);
  const elementRef = useRef<HTMLDivElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(elementRef);
  const [displayLeftPane, setDisplayLeftPane] = useState(true);
  const mobileBarRef = useRef<HTMLDivElement>(null);
  let menuDisplayMode: MenuDisplayMode = MenuDisplayMode.DEFAULT;
  let menuMobileMode = false;
  let enableSwitchDisplayMode = true;

  useOuterClick(menuContainerRef, () => {
    if (menuMobileMode && displayLeftPane) {
      setDisplayLeftPane(false);
    }
  });

  if (size) {
    if (size.width && size.width < 1000) {
      menuDisplayMode = MenuDisplayMode.DEFAULT;
      enableSwitchDisplayMode = false;
      menuMobileMode = true;
    }
  }

  useEffect(() => {
    if (socketStatus === SocketStatus.Online) {
      socketFirstConnectionRef.current = false;
    }
  }, [socketStatus])

  const renderSocketBar = () => {
    if (authStatus !== AuthStatus.In) return null;
    if (socketStatus === SocketStatus.Loading && !socketFirstConnectionRef.current) {
      return (
        <div className={'socketInfoContainer socketInfoContainerLoading'}>
          {'Chargement ...'/* TRANSLATION */}
        </div>
      );
    }
    if (socketStatus === SocketStatus.Offline) {
      return (
        <div className={'socketInfoContainer socketInfoContainerOffline'}>
          {'Déconnecté'/* TRANSLATION */}
          <Touchable className={'socketRetryButton'} onPress={() => {
            dispatch(socketConnect());
          }}>
            {'Se reconnecter'/* TRANSLATION */}
          </Touchable>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={styles.mainContainer} ref={elementRef}>
      {
        menuMobileMode ? (
          <div className={styles.mobileBar} ref={mobileBarRef}>
            <TouchableImage
              onPress={() => {
                setDisplayLeftPane(!displayLeftPane);
              }}
              src={MenuOutline}
              alt=""
              className={styles.mobileBarButton}
              imageStyle={{ width: '50px', height: '50px' }}
            />
          </div>
        ) : null
      }
      {renderSocketBar()}
      <div className={styles.main}>
        <div ref={menuContainerRef}>
          <Menu
            display={displayLeftPane}
            displayMode={menuDisplayMode}
            enableSwitchDisplayMode={enableSwitchDisplayMode}
            onNavigateTo={(itemName: MenuItemName, path: string) => {
            }}
            onDisplayMode={(mode: MenuDisplayMode) => {
              if (!mobileBarRef.current) return;
              if (mode === MenuDisplayMode.SMALL) {
                mobileBarRef.current.style.display = 'none';
              } else {
                mobileBarRef.current.style.removeProperty('display');
              }
            }}
          />
        </div>
        <div className={styles.rightPane}>
          <div className={styles.rightPaneContent}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
