import React from 'react';
import { useOvenMediaStats } from '../../../store/context/hooks';
import styles from './player.module.css';



export interface DashboardPlayerContainerProps {
  className?: string;
  style?: React.CSSProperties;
}

const DashboardPlayerContainer = ({
  className,
  style,
}: DashboardPlayerContainerProps) => {
  const stats = useOvenMediaStats();
  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div className={classes.join(' ')} style={style}>
      <div className={styles.sectionTitle}>{'PLAYERS:' /* TRANSLATION */}</div>
      <div className={styles.rowContainer} >
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'HLS' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {stats?.stats?.connections?.hls ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'LLHLS' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {stats?.stats?.connections?.llhls ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'DASH' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {stats?.stats?.connections?.dash ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'LLDASH' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {stats?.stats?.connections?.lldash ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'OVT' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {stats?.stats?.connections?.ovt ?? 0}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsTitle}>
            {'WEBRTC' /* TRANSLATION */}
          </div>
          <div className={styles.detailsValue}>
            {stats?.stats?.connections?.webrtc ?? 0}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardPlayerContainer.defaultProps = {};

export default DashboardPlayerContainer;
