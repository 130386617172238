import { Touchable } from '@kalyzee/kast-app-web-components';
import React, { useEffect, useRef, useState } from 'react';
import PageContainer from '../../components/page/PageContainer';
import { delay } from '../../helpers/utils';
import { useSocketAppDispatch } from '../../hooks/app';
import { useReduxAction } from '../../hooks/store';
import { ActionState } from '../../store/action';
import { contextOnLogs } from '../../store/context/actions';
import { socketGetLogs, socketSubscribeLogs, socketUnsubscribeLogs } from '../../store/socket/actions';
import styles from './logs.module.css';


const LogsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const socketDispatch = useSocketAppDispatch();
  const logsContainerRef = useRef<HTMLDivElement>(null);
  const logsRef = useRef<string>('En attente de logs ...' /* TRANSLATION */);

  const initLogs = async () => {
    await delay(500); // fix undefined result
    const result = await socketDispatch(socketGetLogs());
    if (result && !result.error && result.response) {
      logsRef.current = result?.response;
      setLoading(false);
      setTimeout(() => {
        updateLogsContainerContent(true);
        socketDispatch(socketSubscribeLogs());
      }, 100);
    }
  }

  const updateLogsContainerContent = (forceScrollToBottom: boolean = false) => {
    if (!logsContainerRef.current) return;
    const scrollTop = logsContainerRef.current.scrollTop;
    const height = logsContainerRef.current.offsetHeight;
    const contentHeight = logsContainerRef.current.scrollHeight;
    const scrollBottom = contentHeight - height  - scrollTop;
    const margerToScrollToBottom = 50;
    logsContainerRef.current.textContent = logsRef.current;

    if (forceScrollToBottom || scrollBottom < margerToScrollToBottom) {
      logsContainerRef.current.scrollTo({
        top: logsContainerRef.current.scrollHeight,
        behavior: 'auto'
      });
      setTimeout(() => {
        if (!logsContainerRef.current) return;
        logsContainerRef.current.scrollTo({
          top: logsContainerRef.current.scrollHeight,
          behavior: 'auto'
        });
      }, 20);
    }

  }

  useReduxAction((action: ActionState) => {
    const logs = action?.payload?.content;
    const append = action?.payload?.append;
    if (!logs) return;
    if (logsContainerRef.current) {
      if (append) {
        logsRef.current = (logsContainerRef.current.textContent ?? '') + logs;        
      } else {
        logsRef.current = logs;
      }
      updateLogsContainerContent();
    }
  }, contextOnLogs.type, []);

  useEffect(() => {
    // SUBSCRIBE TO LOGS
    initLogs();
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    
    return () => {
      clearTimeout(timeout);
      // UNSUBSCRIBE TO LOGS
      socketDispatch(socketUnsubscribeLogs());
    }
  }, []);

  return (
    <PageContainer
      title={'Logs' /* TRANSLATION */}
      subtitle={''}
      loading={loading}
    >
      <div className={styles.logsContainer} ref={logsContainerRef}>
        { logsRef.current }
      </div>
      <Touchable className={styles.refreshButton} onPress={async () => {
        const result = await socketDispatch(socketGetLogs());
        if (!result.error) {
          logsRef.current = result?.response;
          updateLogsContainerContent(true);
        }
      }}>
        {'Recharger' /* TRANSLATION */}
      </Touchable>
    </PageContainer>
  );
};

export default LogsPage;
