import React, { useEffect, useImperativeHandle, useRef } from 'react';

import styles from './PageContainer.module.css';
import PageTitle from './PageTitle';

import IconLoading from '../../assets/icons/loading.svg';
import { Size, useElementSize } from '../../hooks/window';

const DEFAULT_LOADING = false;

export interface PageContainerRef {
  getElementRef: () => React.RefObject<HTMLDivElement>;
}

export interface PageContainerProps {
  title: string;
  subtitle?: string;
  loading?: boolean;
  onSize?: (size : Size) => void;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const PageContainer = React.forwardRef((
  {
    title,
    subtitle,
    loading,
    onSize,
    children,
    className,
    style,
  }: PageContainerProps,
  forwardRef: React.ForwardedRef<PageContainerRef | undefined>,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const elementSize = useElementSize(ref);
  const classes = [styles.container];
  if (className) classes.push(className);

  const renderLoading = () => (
    <div className={styles.loadingContainer}>
      <img className={styles.loadingIcon} alt="" src={IconLoading} />
    </div>
  );

  useEffect(() => {
    onSize?.(elementSize);
  }, [elementSize]);

  useImperativeHandle(forwardRef, () => ({
    getElementRef: () => ref,
  }));

  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={ref}
    >
      <PageTitle
        className={styles.title}
        title={title}
        subtitle={subtitle}
      />
      <div
        className={styles.content}
      >
        {
          loading
            ? renderLoading()
            : children
        }
      </div>
    </div>
  );
});

PageContainer.defaultProps = {
  subtitle: undefined,
  loading: DEFAULT_LOADING,
  onSize: undefined,
  children: undefined,
  className: undefined,
  style: undefined,
};

export default PageContainer;
