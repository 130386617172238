import React from 'react';
import PageContainer from '../../components/page/PageContainer';
import { useOvenMediaStats } from '../../store/context/hooks';
import DashboardNetworkContainer from './containers/network';
import DashboardPlayerContainer from './containers/player';
import DashboardStreamContainer from './containers/stream';
import DashboardSystemContainer from './containers/system';


const DashboardPage = () => {
  const stats = useOvenMediaStats();

  return (
    <PageContainer
      title={'Dashboard' /* TRANSLATION */}
      subtitle={''}
      loading={stats ? false : true}
    >
      <div style={{ width: '100%' }}>
        <DashboardStreamContainer style={{ marginBottom: '10px' }} />
        <DashboardSystemContainer style={{ marginBottom: '10px' }} />
        <DashboardPlayerContainer style={{ marginBottom: '10px' }} />
        <DashboardNetworkContainer style={{ marginBottom: '10px' }} />
      </div>
    </PageContainer>
  );
};

export default DashboardPage;
