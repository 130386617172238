import { PopupButtonType, showPopup, Touchable } from "@kalyzee/kast-app-web-components";
import React, { useImperativeHandle, useRef } from "react";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableStyle } from "../utils/Table";
import { useRender } from "../../hooks/component";
import { useElementSize } from "../../hooks/window";
import { OvenMediaTrackAudio } from "../../interfaces/context";
import { useOvenMediaContext } from "../../store/context/hooks";
import styles from "./table-audio-track.module.css";

export interface TableAudioTrackData extends OvenMediaTrackAudio {}

export interface TableAudioTrackRef {
  render: () => void;
}

export interface TableAudioTrackProps {
  data: TableAudioTrackData[];
  onItemChecked?: (item: TableAudioTrackData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableAudioTrack = React.forwardRef(
  ({ data, onItemChecked, className, style }: TableAudioTrackProps, forwardRef: React.ForwardedRef<TableAudioTrackRef | undefined>) => {
    const context = useOvenMediaContext();
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();
    const size = useElementSize(containerRef);

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TableAudioTrackData>[] = [
        /* {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      }, */
        {
          type: TableColumnType.CLASSIC,
          key: "name",
          minWidth: "8rem",
          title: "Name" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
        },
        {
          type: TableColumnType.CLASSIC,
          key: "bypass",
          title: "Bypass" /* TRANSLATION */,
          width: "3.5rem",
        },
        {
          type: TableColumnType.CLASSIC,
          key: "codec",
          title: "Codec" /* TRANSLATION */,
          width: "4rem",
        },
        {
          type: TableColumnType.CLASSIC,
          key: "channel",
          title: "Channel" /* TRANSLATION */,
          width: "4rem",
        },
        {
          type: TableColumnType.CLASSIC,
          key: "bitrate",
          title: "Bitrate" /* TRANSLATION */,
          width: "5rem",
        },
        {
          type: TableColumnType.CLASSIC,
          key: "samplerate",
          title: "Samplerate" /* TRANSLATION */,
          width: "5.5rem",
        },
      ];

      const tableConfiguration: TableConf<TableAudioTrackData> = {
        columns: columnConfiguration,
        header: {
          className: styles.tableHeader,
          cell: {
            className: styles.tableHeaderCell,
          },
        },
        row: {
          className: styles.tableRow,
          cell: {
            className: styles.tableRowCell,
          },
        },
        content: { className: styles.tableContent },
        valueToShowIfUndefined: { value: "-", className: styles.tableUndefinedValue },
      };

      return tableConfiguration;
    };

    // Called when a value is changed. Checkboxes here
    const valueChanged = (value: any, columnKey: string, item: TableAudioTrackData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TableAudioTrackData) => {
      if (columnKey === "bypass") {
        if (item.audio?.bypass) {
          return <div className={styles.bypassYes}>✔</div>;
        }
        return <div className={styles.bypassFalse}>✗</div>;
      }
      if (columnKey === "bitrate") {
        const bitrate = Number(item.audio.bitrate ?? "0");
        const bps = Number.isNaN(bitrate) ? 0 : bitrate;
        const kbps = (bps / 1000).toFixed(2);
        const mbits = (bps / 1000000).toFixed(2);
        return (
          <Touchable
            className={styles.bitrate}
            onPress={() => {
              showPopup(
                {
                  content: (
                    <div style={{ minWidth: 300 }}>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Bitrate:" /* TRANSLATION */}</div>
                      <div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                          <div>bits/s: </div>
                          <div style={{}}>{`${bps} bits/s`}</div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                          <div>kbits/s: </div>
                          <div style={{}}>{`${kbps} kbits/s`}</div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" }}>
                          <div>Mbits/s: </div>
                          <div style={{}}>{`${mbits} Mbits/s`}</div>
                        </div>
                      </div>
                    </div>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                },
                undefined,
                { displayHover: true }
              );
            }}
          >
            {mbits}
          </Touchable>
        );
      }
      return element;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TableAudioTrackData) => {
      const result: TableStyle = {};
      return result;
    };

    const addCustomStyleOnRow = (item: TableAudioTrackData, currData: TableAudioTrackData[], index: number) => {
      const result: TableStyle = {};
      return result;
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const transformValue = (columnKey: string, item: TableAudioTrackData, initialValue: any, data: TableAudioTrackData[], index: number) => {
      return (item.audio as any)?.[columnKey];
    };

    const renderTable = () => (
      <Table
        className={styles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.name}`}
        configuration={generateConfiguration()}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
        transformValue={transformValue}
      />
    );

    const classes = [styles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

TableAudioTrack.defaultProps = {
  className: undefined,
  style: undefined,
};

export default TableAudioTrack;
