import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LoginRoute from '../components/navigation/LoginRoute';
import PrivateRoute from '../components/navigation/PrivateRoute';
import { useAppDispatch, useAppSelector } from '../hooks/app';
import { useReduxAction } from '../hooks/store';
import { AuthStatus } from '../interfaces/session';
import ApplicationPage from '../pages/application/application';
import DashboardPage from '../pages/dashboard/dashboard';
import HistoriyPage from '../pages/history/history';
import HomePage from '../pages/home/home';
import LoginPage from '../pages/login/login';
import LogoutPage from '../pages/logout/logout';
import LogsPage from '../pages/logs/logs';
import PlaylistsPage from '../pages/playlists/playlists';
import { selectAuthStatus, selectToken } from '../store/session/selectors';
import { setAuthStatus } from '../store/session/slices';
import './App.css';
import { RouterProvider } from './RouterContext';
import PlayerPage from '../pages/player/player';
import { getConfQueries } from '../helpers/request';
import RecordPage from '../pages/records/records';
import UploadProfilesPage from '../pages/uploadProfiles/upload-profiles';

function App() {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const token = useAppSelector(selectToken);
  const location = useLocation();

  useEffect(() => {
    if (token) {
      dispatch(setAuthStatus(AuthStatus.In));
    }
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const confQueries = getConfQueries();
    Object.keys(confQueries).forEach((q) => {
      const v = confQueries[q];
      if (v) url.searchParams.set(q, v);
    });
    window.history.replaceState(window.history.state, '', url.href);
 }, [location]);

  return (
    <RouterProvider>
      <Routes>
        { authStatus === AuthStatus.In ? (
        <Route element={<PrivateRoute redirectPath="login" authStatus={authStatus} />}>
          <Route path="/" element={<HomePage />}>
            <Route index element={<DashboardPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="application/:app" element={<ApplicationPage />} />
            <Route path="application/" element={<ApplicationPage />} />
            <Route path="history/:app" element={<HistoriyPage />} />
            <Route path="history/" element={<HistoriyPage />} />
            <Route path="records/:app" element={<RecordPage />} />
            <Route path="records/" element={<RecordPage />} />
            <Route path="upload-profiles/" element={<UploadProfilesPage />} />
            <Route path="playlists/:app" element={<PlaylistsPage />} />
            <Route path="player/:app/:stream" element={<PlayerPage />} />
            <Route path="logs" element={<LogsPage />} />
          </Route>
          <Route path="logout" element={<LogoutPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        ): null}
         { authStatus === AuthStatus.Out || authStatus === AuthStatus.Loading ? (
        <Route element={<LoginRoute redirectPath="/" authStatus={authStatus} />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="login" replace />} />
        </Route>
        ): null}
        <Route path="*" />
      </Routes>
    </RouterProvider>
  );
}

export default App;
