import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, combineReducers, configureStore, Middleware, PreloadedState } from '@reduxjs/toolkit';
import socketReducer from './socket/slices';
import sessionReducer from './session/slices';
import contextReducer from './context/slices';

import { socketMiddleware } from './socket/middlewares';
import { requestMiddleware } from './request/middlewares';
import { actionReducer } from './action';

export type StoreAction = ActionCreatorWithPayload<any, string> | ActionCreatorWithoutPayload<string>;

const middlewares = [socketMiddleware, requestMiddleware];

const rootReducer = combineReducers({
  socket: socketReducer,
  session: sessionReducer,
  context: contextReducer,
  action: actionReducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => (
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  })
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

export default setupStore;
