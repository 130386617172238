import {
  closePopup,
  getDateDifferenceText,
  OverlayHoverMessage,
  PopupButtonType,
  PopupIconType,
  showPopup,
  TableSortDirection,
  Touchable,
} from "@kalyzee/kast-app-web-components";
import React, { createRef, useImperativeHandle, useRef } from "react";
import { ReactComponent as IconClose } from "../../assets/icons/close.svg";
import { ReactComponent as IconData } from "../../assets/icons/data.svg";
import { ReactComponent as IconHls } from "../../assets/icons/file-hls.svg";
import { ReactComponent as IconMp4 } from "../../assets/icons/file-mp4.svg";
import { ReactComponent as IconXml } from "../../assets/icons/file-xml.svg";
import { ReactComponent as IconId } from "../../assets/icons/id.svg";
import { ReactComponent as IconTracks } from "../../assets/icons/tracks.svg";
import Colors from "../../constants/colors";
import { getBaseUrl } from "../../helpers/request";
import { toastError, toastSuccess } from "../../helpers/toast";
import { addToPathNameUrl } from "../../helpers/utils";
import { useSocketAppDispatch } from "../../hooks/app";
import { useRender } from "../../hooks/component";
import {
  OvenMediaRecordSession,
  OvenMediaRecordSessionStatus,
  OvenMediaStreamContext,
  OvenMediaTrackAudio,
  OvenMediaTrackVideo,
} from "../../interfaces/context";
import { useOvenMediaContext } from "../../store/context/hooks";
import { socketUploadRecord } from "../../store/socket/actions";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableStyle } from "../utils/Table";
import TableAudioTrack from "./table-audio-track";
import styles from "./table-record.module.css";
import TableUpload from "./table-upload";
import TableVideoTrack from "./table-video-track";

export interface TableRecordData {
  context: OvenMediaStreamContext;
  session: OvenMediaRecordSession;
}

export interface TableRecordRef {
  render: () => void;
}

export interface TableRecordProps {
  showApp?: boolean;
  data: TableRecordData[];
  sort?: { field: string; direction: -1 | 1 };
  onSort?: (field: string, direction: -1 | 1) => void;
  onStopRecord?: (item: TableRecordData) => void;
  onDeleteRecord?: (item: TableRecordData) => void;
  onItemChecked?: (item: TableRecordData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableRecord = React.forwardRef(
  (
    { showApp, sort, onSort, data, onStopRecord, onDeleteRecord, onItemChecked, className, style }: TableRecordProps,
    forwardRef: React.ForwardedRef<TableRecordRef | undefined>
  ) => {
    const context = useOvenMediaContext();
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();
    const socketDispatch = useSocketAppDispatch();

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TableRecordData>[] = [
        {
          type: TableColumnType.CLASSIC,
          key: "id",
          width: "35px",
          title: "Id" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
        },
        {
          type: TableColumnType.CLASSIC,
          key: "startedAt",
          minWidth: "13rem",
          title: "Début" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "streamId",
          width: "35px",
          title: "" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "key",
          width: "10rem",
          title: "Live (id / key)" /* TRANSLATION */,
          enableSort: true,
        },
        ...(showApp
          ? [
              {
                type: TableColumnType.CLASSIC,
                key: "app",
                width: "10rem",
                title: "App" /* TRANSLATION */,
                ascendantSort: (a: TableRecordData, b: TableRecordData) => a.session.app.localeCompare(b.session.app),
                descendantSort: (a: TableRecordData, b: TableRecordData) => b.session.app.localeCompare(a.session.app),
                enableSort: true,
              },
            ]
          : []),
        {
          type: TableColumnType.CLASSIC,
          key: "endedAt",
          width: "15rem",
          title: "Fin" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "duration",
          width: "6rem",
          title: "Duration" /* TRANSLATION */,
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "expireAt",
          width: "15rem",
          title: "Expire le" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "deleted",
          width: "5rem",
          title: "Supprimé" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "status",
          title: "Status" /* TRANSLATION */,
          width: "8rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "tracks",
          title: "Tracks" /* TRANSLATION */,
          width: "3rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "data",
          title: "Data" /* TRANSLATION */,
          width: "3rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "upload",
          title: "Upload" /* TRANSLATION */,
          width: "5rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "actions",
          title: "Actions" /* TRANSLATION */,
          width: "12rem",
          enableSort: false,
        },
      ];

      const defaultSort = sort ?? { field: "startedAt", direction: -1 };
      columnConfiguration.forEach((c) => {
        if (c.key === defaultSort.field) {
          c.defaultSort = defaultSort.direction === 1 ? TableSortDirection.ASC : TableSortDirection.DESC;
        }
      });

      const tableConfiguration: TableConf<TableRecordData> = {
        columns: columnConfiguration,
        header: {
          className: styles.tableHeader,
          cell: {
            className: styles.tableHeaderCell,
          },
        },
        row: {
          className: styles.tableRow,
          cell: {
            className: styles.tableRowCell,
          },
        },
        content: { className: styles.tableContent },
        valueToShowIfUndefined: { value: "-", className: styles.tableUndefinedValue },
      };

      return tableConfiguration;
    };

    // Called when a value is changed. Checkboxes here
    const valueChanged = (value: any, columnKey: string, item: TableRecordData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TableRecordData) => {
      if (columnKey === "id") {
        const ref = createRef<HTMLDivElement>();
        const id = item.session?.id ?? "";
        return (
          <div ref={ref} className={styles.name}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Id:" /* TRANSLATION */}</div>
                      <div>{id}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              <IconId width={17} height={17} />
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={id} />
          </div>
        );
      }
      if (columnKey === "streamId") {
        const ref = createRef<HTMLDivElement>();
        const id = item.session?.liveSession?.id ?? "";
        return (
          <div ref={ref} className={styles.name}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Id:" /* TRANSLATION */}</div>
                      <div>{id}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              <IconId width={17} height={17} />
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={id} />
          </div>
        );
      }
      if (columnKey === "key") {
        const ref = createRef<HTMLDivElement>();
        const key = item.context.stream;
        return (
          <div ref={ref} className={styles.key}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Key:" /* TRANSLATION */}</div>
                      <div>{key}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {key}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={key} />
          </div>
        );
      }
      if (columnKey === "app") {
        return (
          <Touchable
            className={[styles.app, styles.textOverflow].join(" ")}
            onPress={() => {
              showPopup({
                content: (
                  <>
                    <div style={{ marginBottom: 10 }}>{"App:" /* TRANSLATION */}</div>
                    <div>{item.session.app}</div>
                  </>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {item.session.app}
          </Touchable>
        );
      }
      if (columnKey === "data") {
        if (!item.session?.data) return "-";
        return (
          <Touchable
            className={styles.actionPlay}
            onPress={() => {
              let data = item.session?.data;
              if (data) {
                try {
                  const json = JSON.parse(data);
                  data = JSON.stringify(json, null, 4);
                } catch (err) {}
              }

              showPopup({
                content: (
                  <div>
                    <div className={styles.popupTitle}>Data:</div>
                    {data}
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            <IconData width={20} height={20} />
          </Touchable>
        );
      }
      if (columnKey === "actions") {
        const iconSize = 30;
        const status = item.session.status;
        const id = item.session.id;
        const hlsUrl = addToPathNameUrl(getBaseUrl(), `/record/${id}/file.m3u8`);
        const xmlUrl = addToPathNameUrl(getBaseUrl(), `/record/${id}/file.xml`);
        const mp4Url = item.session.mp4 ? addToPathNameUrl(getBaseUrl(), `/videos/${item.session.mp4}`) : undefined;

        if (!id) return null;
        const style: React.CSSProperties = {};
        const vodDeleted = item.session?.deleted;
        const vodDeletedMessage = () => {
          showPopup({
            title: "Vidéo supprimée",
            iconTitle: PopupIconType.INFO,
            content: `La vidéo a été supprimée.`,
            enableBackdropDismiss: true,
            enableCloseButton: true,
          });
        };
        return (
          <div className={styles.actions} style={style}>
            <Touchable
              onPress={() => {
                if (vodDeleted) vodDeletedMessage();
                else window.open(hlsUrl, "_blank");
              }}
            >
              <IconHls width={iconSize} height={iconSize} />
            </Touchable>
            <Touchable
              onPress={() => {
                if (vodDeleted) vodDeletedMessage();
                else window.open(xmlUrl, "_blank");
              }}
            >
              <IconXml width={iconSize} height={iconSize} />
            </Touchable>
            {mp4Url ? (
              <Touchable
                onPress={() => {
                  if (vodDeleted) vodDeletedMessage();
                  else window.open(mp4Url, "_blank");
                }}
              >
                <IconMp4 width={iconSize} height={iconSize} />
              </Touchable>
            ) : null}
            {status === OvenMediaRecordSessionStatus.RECORDING ? (
              <Touchable
                onPress={() => {
                  showPopup({
                    title: "Arrêter l'enregistrement",
                    iconTitle: PopupIconType.WARNING,
                    content: `Êtes-vous sûr de vouloir arrêter l'enregistrement ?`,
                    buttons: [
                      { type: PopupButtonType.CANCEL, element: "Non" },
                      {
                        type: PopupButtonType.VALIDATE,
                        element: "Oui",
                        onClick: async () => {
                          onStopRecord?.(item);
                          return true;
                        },
                      },
                    ],
                    enableBackdropDismiss: true,
                    enableCloseButton: true,
                  });
                }}
              >
                <IconClose stroke={Colors.getTorchRed()} strokeWidth={2} width={iconSize} height={iconSize} />
              </Touchable>
            ) : status === OvenMediaRecordSessionStatus.TERMINATED && !item.session.deleted ? (
              <Touchable
                onPress={() => {
                  showPopup({
                    title: "Supprimer l'enregistrement",
                    iconTitle: PopupIconType.WARNING,
                    content: `Êtes-vous sûr de vouloir supprimer l'enregistrement ?`,
                    buttons: [
                      { type: PopupButtonType.CANCEL, element: "Non" },
                      {
                        type: PopupButtonType.VALIDATE,
                        element: "Oui",
                        onClick: async () => {
                          onDeleteRecord?.(item);
                          return true;
                        },
                      },
                    ],
                    enableBackdropDismiss: true,
                    enableCloseButton: true,
                  });
                }}
              >
                <IconClose stroke={Colors.getTorchRed()} strokeWidth={2} width={iconSize} height={iconSize} />
              </Touchable>
            ) : null}
          </div>
        );
      }
      if (columnKey === "tracks") {
        const tracks = item.session.tracks;
        const videoTracks: OvenMediaTrackVideo[] = [];
        const audioTracks: OvenMediaTrackAudio[] = [];

        if (tracks && item.session.tracks && item.context.outputs) {
          item.context.outputs.forEach((o) => {
            if (o.tracks) {
              o.tracks.forEach((track) => {
                if (tracks.includes(track.name)) {
                  if (track.type === "Video") {
                    videoTracks.push(track);
                  } else if (track.type === "Audio") {
                    audioTracks.push(track);
                  }
                }
              });
            }
          });
        }

        return (
          <Touchable
            className={styles.tracks}
            onPress={() => {
              showPopup({
                content: (
                  <div style={{ minWidth: 300 }}>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Tracks:" /* TRANSLATION */}</div>
                    {
                      <div className={styles.tracksContainer}>
                        <div>{JSON.stringify(tracks)}</div>
                        {videoTracks.length ? (
                          <>
                            <div style={{ marginTop: "10px" }} />
                            <div className={styles.tracksSeparator}>
                              <div className={styles.tracksSeparatorLine} />
                              <div className={styles.tracksSeparatorTitle}>{"Video" /* TRANSLATION */}</div>
                              <div className={styles.tracksSeparatorLine} />
                            </div>
                            <TableVideoTrack data={videoTracks} />
                          </>
                        ) : null}
                        {audioTracks.length ? (
                          <>
                            <div className={styles.tracksSeparator} style={{ marginTop: "10px" }}>
                              <div className={styles.tracksSeparatorLine} />
                              <div className={styles.tracksSeparatorTitle}>{"Audio" /* TRANSLATION */}</div>
                              <div className={styles.tracksSeparatorLine} />
                            </div>
                            <TableAudioTrack data={audioTracks} />
                          </>
                        ) : null}
                      </div>
                    }
                    <br />
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            <IconTracks width={30} height={30} />
          </Touchable>
        );
      }
      if (columnKey == "tracks") {
        if (!item.session.tracks) return undefined;
        const ref = createRef<HTMLDivElement>();
        return (
          <div ref={ref}>
            {`[${item.session.tracks?.join(", ")}]`}
            <OverlayHoverMessage targetRef={ref} message={`Tracks: [${item.session.tracks?.join(", ")}]`} />
          </div>
        );
      }
      if (columnKey == "upload") {
        const uploads = item.session.uploadSessions;
        return (
          <Touchable
            className={styles.upload}
            onPress={() => {
              const uploadProfiles = context?.uploadProfiles;
              const upload = () => {
                if (!uploadProfiles?.length) {
                  toastError(`Aucun upload profile n'est encor enregistré`);
                  return;
                }
                let profileButtonContainerRef: (React.RefObject<HTMLDivElement> | null)[] = [];
                let currProfile = uploadProfiles[0];
                showPopup(
                  {
                    title: "Upload le record ?",
                    iconTitle: PopupIconType.INFO,
                    content: (
                      <div>
                        <div className={styles.popupTitle}>Choisissez l'upload profile:</div>
                        <div className={styles.popupProfileSelectionContainer}>
                          <div className={styles.popupProfileSelectionList}>
                            {uploadProfiles.map((u, i) => {
                              const ref = createRef<HTMLDivElement>();
                              const currClasses = [styles.popupProfileSelectionButton];
                              if (u === currProfile) currClasses.push(styles.popupProfileSelectionButtonSelected);
                              profileButtonContainerRef[i] = ref;
                              return (
                                <div key={u.id} ref={ref} className={currClasses.join(" ")}>
                                  <Touchable
                                    onPress={() => {
                                      currProfile = u;
                                      profileButtonContainerRef.forEach((r, j) => {
                                        const classList = r?.current?.classList;
                                        if (i === j) classList?.add(styles.popupProfileSelectionButtonSelected);
                                        else classList?.remove(styles.popupProfileSelectionButtonSelected);
                                      });
                                    }}
                                  >
                                    {`${u.name ?? `${u.host} - ${u.type}`}`}
                                  </Touchable>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ),
                    buttons: [
                      { type: PopupButtonType.CANCEL, element: "Annuler" },
                      {
                        type: PopupButtonType.VALIDATE,
                        element: "Valider" /* TRANSLATION */,
                        onClick: async () => {
                          const profile = currProfile;
                          const result = await socketDispatch(socketUploadRecord({ recordSession: item.session.id!, uploadProfile: profile.id }));
                          closePopup(popupIndex);
                          if (result.error) {
                            toastError(`Impossible de créer une nouvelle redirection. Error : ${result.error}`);
                          } else {
                            toastSuccess(
                              `L'upload a démarré. Veuillez actualiser la page pour afficher l'évolution de l'upload (cela peut prendre un certain temps).`
                            );
                          }
                          return true;
                        },
                      },
                    ],
                    enableBackdropDismiss: false,
                    enableCloseButton: false,
                  },
                  undefined,
                  { displayHover: true }
                );
              };

              const popupIndex = showPopup({
                style: { width: "90%" },
                content: (
                  <div className={styles.popupUploadContainer}>
                    <div className={styles.popupTitle}>{"Uploads:" /* TRANSLATION */}</div>
                    <div>{uploads?.length ? <TableUpload data={uploads} /> : <div>Aucun upload n'a été encore fait... </div>}</div>
                    {uploadProfiles?.length ? (
                      <Touchable
                        className={styles.popupUpload}
                        onPress={async () => {
                          upload();
                        }}
                      >
                        Upload le record
                      </Touchable>
                    ) : null}
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {uploads?.length ?? 0}
          </Touchable>
        );
      }
      if (columnKey == "deleted") {
        if (item.session?.deleted) return <div style={{ color: Colors.getTorchRed() }}>true</div>;
        return <div style={{ color: Colors.getMainGreen() }}>false</div>;
      }
      return element;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TableRecordData) => {
      const result: TableStyle = {};
      return result;
    };

    const addCustomStyleOnRow = (item: TableRecordData, currData: TableRecordData[], index: number) => {
      const result: TableStyle = {};
      return result;
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const transformValue = (columnKey: string, item: TableRecordData, initialValue: any, data: TableRecordData[], index: number) => {
      if (columnKey == "startedAt") {
        if (!item.session?.startedAt) return undefined;
        const startedAt = new Date(item.session?.startedAt);
        return `${startedAt.toLocaleDateString()} ${startedAt.toLocaleTimeString()}`;
      }
      if (columnKey == "endedAt") {
        if (!item.session?.endedAt) return undefined;
        const endedAt = new Date(item.session?.endedAt);
        return `${endedAt.toLocaleDateString()} ${endedAt.toLocaleTimeString()}`;
      }
      if (columnKey === "duration") {
        if (item.session?.startedAt && !item.session?.endedAt) return "en cours...";
        else if (!item.session?.startedAt || !item.session?.endedAt) return undefined;

        const startedAt = new Date(item.session.startedAt);
        const endedAt = new Date(item.session.endedAt);
        return getDateDifferenceText(startedAt, endedAt);
      }
      if (columnKey == "tracks") {
        return item.session.tracks ? "" : undefined;
      }
      if (columnKey == "status") {
        return item.session.status;
      }
      if (columnKey == "expireAt") {
        if (!item.session?.expireAt) return undefined;
        const startedAt = new Date(item.session?.expireAt);
        return `${startedAt.toLocaleDateString()} ${startedAt.toLocaleTimeString()}`;
      }
      if (columnKey == "app") {
        return item.session?.app;
      }
    };

    const _onSort = (columnKey: string, direction: TableSortDirection | undefined) => {
      let numberDirection: 0 | 1 | -1 = 0;
      if (direction === TableSortDirection.ASC) numberDirection = 1;
      else if (direction === TableSortDirection.DESC) numberDirection = -1;
      if (numberDirection !== 0) onSort?.(columnKey, numberDirection);
    };

    const renderTable = () => (
      <Table
        className={styles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.session.id}`}
        configuration={generateConfiguration()}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
        transformValue={transformValue}
        onSort={_onSort}
      />
    );

    const classes = [styles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

TableRecord.defaultProps = {
  className: undefined,
  style: undefined,
};

export default TableRecord;
