import { useAppSelector } from '../../hooks/app';
import { OvenMediaContext } from '../../interfaces/context';
import { OvenMediaStats } from '../../interfaces/stats';
import { selectContext, selectStats } from './selectors';

export const useOvenMediaContext = () : OvenMediaContext | undefined => useAppSelector(selectContext);
export const useOvenMediaStats = () : OvenMediaStats | undefined => useAppSelector(selectStats);

export default {
  useOvenMediaContext,
  useOvenMediaStats,
};
