

export const getEnvApiUrl = () : string | undefined => {
  // see scripts/set-env.js & public/load_env.js
  let url;
  const customLocalData = (window as any).__custom_local_data__;
  if (customLocalData?.API_URL) {
    try {
      const urlObj = new URL(customLocalData.API_URL);
      url = urlObj.href;
    } catch {}
  }
  return url;
}


export const getEnvOvenMediaWebsocketUrl = () : string | undefined => {
  // see scripts/set-env.js & public/load_env.js
  let url;
  const customLocalData = (window as any).__custom_local_data__;
  if (customLocalData?.MEDIA_SERVER_WEBSOCKET_URL) {
    try {
      const urlObj = new URL(customLocalData.MEDIA_SERVER_WEBSOCKET_URL);
      url = urlObj.href;
    } catch {}
  }
  return url;
}