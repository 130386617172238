import React from 'react';
import DashboardNetworkStats from '../../../components/dashboard/network';
import { useOvenMediaStats } from '../../../store/context/hooks';
import styles from './network.module.css';



export interface DashboardNetworkContainerProps {
  className?: string;
  style?: React.CSSProperties;
}


const DashboardNetworkContainer = ({
  className,
  style,
}: DashboardNetworkContainerProps) => {
  const stats = useOvenMediaStats();
  if (!stats?.network) return null;
  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div className={classes.join(' ')} style={style}>
      <div className={styles.sectionTitle}>NETWORK:</div>
      <div className={styles.cardsContainer}>
        <DashboardNetworkStats
          mode='incoming'
          stats={stats.network}
          className={styles.detailsContainer}
        />
        <DashboardNetworkStats
          mode='outgoing'
          stats={stats.network}
          className={styles.detailsContainer}
        />
      </div>
    </div>
  );
};

DashboardNetworkContainer.defaultProps = {};


export default DashboardNetworkContainer;
